import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const useNavigate = () => {
  const { navigate } = useI18next()

  return navigate
}

const linkStyle = 'text-gray-24 underline cursor-pointer'

const P = tw.p`mb-4 leading-6`

const BackIcon = tw(IoIosArrowRoundBack)``

const MyLink = styled(Link)`
  ${tw`${linkStyle}`}
`

export const OutLink = styled(OutboundLink)`
  ${tw`${linkStyle}`}
`

export default MyLink

export const A = styled.a.attrs((props) => {
  return {
    href: props.href ? props.href : '#',
    rel: 'noopener noreferrer',
    target: '_blank',
  }
})`
  ${tw`${linkStyle}`}
`

export const BackLink = styled.a.attrs((props) => {
  const navigate = useNavigate()

  return {
    href: '/#',
    onClick: (e) => {
      e.preventDefault()
      let link = ''
      const res = navigate(-1)
      debugger
    },
  }
})`
  ${tw`${linkStyle}`}
`

export const HomeLink = (domProps) => {
  const { t } = useTranslation()

  return (
    <P tw="flex justify-start items-center" {...domProps}>
      <BackIcon />
      <Link to="/">{t('link.home')}</Link>
    </P>
  )
}
